import find from "lodash/find"
import React from "react"
import Select from "react-select"
import { ModeOfTransit } from "../generated/graphql"
import S from "./ModeOfTransitSelect.styled"

type ModeOfTransitOption = { value: ModeOfTransit; label: string }

const customStyles = {
  option: (provided: any, state: any) => ({
    ...provided,
    color: "black", // Set the text color to black
  }),
  singleValue: (provided: any) => ({
    ...provided,
    color: "black", // Ensure the selected value text is also black
  }),
}

const ModeOfTransitSelect = ({
  value,
  onChange,
}: {
  value: ModeOfTransit
  onChange: (newValue: { value: ModeOfTransit } | null) => void
}) => {
  const options: Array<ModeOfTransitOption> = [
    { value: ModeOfTransit.Air, label: "Air" },
    { value: ModeOfTransit.Sea, label: "Sea" },
  ]

  return (
    <S.Container className="mb-2 mt-2">
      <Select
        className="basic-single"
        classNamePrefix="select"
        value={find(options, { value: value })}
        isSearchable={true}
        name="ShippingPortSelect"
        options={options}
        styles={customStyles}
        onChange={onChange}
      />
    </S.Container>
  )
}

export default ModeOfTransitSelect
