import { TabList, TabPanel, TabPanels } from "@reach/tabs"
import VisuallyHidden from "@reach/visually-hidden"
import clsx from "clsx"
import React, { ReactNode, useState } from "react"
import Imgix from "react-imgix"
import invariant from "tiny-invariant"
import {
  MakerShowMakerFragment,
  useMakerDocumentsQuery,
  useMakerLogisticsQuery,
  useMakerProfileOrdersQuery,
  useMakerShowQuery,
} from "../generated/graphql"
import { useInfiniteGraphQLQuery } from "../hooks/useInfiniteGraphQLQuery"
import { ReactComponent as DownChevronSVG } from "../images/down-chevron.svg"
import { ReactComponent as DownloadSVG } from "../images/download.svg"
import { ReactComponent as EyeSVG } from "../images/eye.svg"
import { ReactComponent as Plus40SVG } from "../images/plus-40.svg"
import { ReactComponent as RightChevronSVG } from "../images/right-chevron.svg"
import { ReactComponent as StarSVG } from "../images/star.svg"
import { ReactComponent as DownChevronIcon } from "../images/down-chevron-dark.svg"
import { ReactComponent as UpChevronIcon } from "../images/up-chevron-dark.svg"
import { formatDate, formatDateTimeLong } from "../util/dates"
import { makerStatusLabels } from "../util/maker-status-labels"
import { todo } from "../util/todo"
import { isLinkableUrl } from "../util/urls"
import {
  BorderBox,
  BorderBoxContainer,
  BorderBoxEmpty,
  BorderBoxHeading,
  BorderBoxItem,
} from "./BorderBox"
import Contact from "./Contact"
import ContactDetail from "./ContactDetail"
import CountriesTable from "./CountriesTable"
import { FormatCentsMaybe } from "./Currency"
import EditLink, { TabEditLink } from "./EditLink"
import { EmptyValue, MaybeNumber, MaybeText } from "./EmptyValue"
import { RequestError } from "./Errors"
import FileDownloadDropdown from "./FileDownloadDropdown"
import { ImageWithControls } from "./LegacyImage"
import LoadingIndicator from "./LoadingIndicator"
import { ModeOfTransitLabel } from "./ModeOfTransitLabel"
import { StyledTab } from "./StyledTab"
import Tabs from "./Tabs"
import { withBasics } from "./withBasics"
import { CircularProgressbar, buildStyles } from "react-circular-progressbar"
import "react-circular-progressbar/dist/styles.css"
import numberWithCommas from "../util/number-with-commas"

const MakerProfile = (props: { slug: string }) => {
  const result = useMakerShowQuery({ slug: props.slug })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />

  invariant(result.data, `expected data`)
  const maker = result.data.maker

  return (
    <div className="d-flex flex-column">
      <div className="d-flex flex-column flex-lg-row">
        <div className="mr-auto mb-2">
          <Header maker={maker} />
        </div>
        <div className="d-flex flex-column text-lg-right align-self-lg-center mb-2">
          <Status maker={maker} />
        </div>
      </div>
      <div className="pt-3 pb-4">
        <CountriesTable countries={maker.countries.nodes} />
      </div>
      <div className="row">
        <div className="col col-md-12 col-lg-4 mb-4">
          <div className="card border-light-teal">
            <div className="card-body">
              <LeftCard maker={maker} />
            </div>
          </div>
        </div>
        <div className="col col-md-12 col-lg-8">
          <div className="card border-light-teal">
            <div className="card-body">
              <RightCard maker={maker} />
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

type MakerProps = {
  maker: MakerShowMakerFragment
}

const MaybeLink = ({ value }: { value: string }) => {
  if (isLinkableUrl(value)) {
    return (
      <a
        href={value}
        target="_blank"
        rel="noreferrer"
        className="text-dark-teal"
      >
        {value}
      </a>
    )
  }

  return <span>{value}</span>
}

const ScoreSummary = ({ maker }: MakerProps) => {
  if (!maker.makerScore) {
    return <EmptyValue variant="message" />
  }

  let scoreType
  let scoreValue

  if (maker.makerScore.performanceScore && maker.makerScore.coreScore) {
    scoreType = "Overall Score:"
    scoreValue = maker.makerScore.overallScore.toFixed(1)
  } else {
    scoreType = "Core Score:"
    scoreValue = maker.makerScore.coreScore.toFixed(1)
  }

  return (
    <div className="d-flex align-items-center mb-2">
      <small className="headings-font mr-4">{scoreType}</small>
      <strong style={{ fontSize: "1.5rem" }}>{scoreValue}</strong>
    </div>
  )
}

const Header = ({ maker }: MakerProps) => (
  <div className="mt-4 d-flex flex-row">
    <div className="mr-30 mt-2">
      <Imgix src={maker.logoUrlWithFallback} sizes="100px" className="shadow" />
    </div>

    <div className="d-flex flex-column">
      <h1
        className="mb-1"
        data-testid="maker-name"
        style={{
          fontSize: 40,
          lineHeight: `48px`,
          letterSpacing: "0.05em",
        }}
      >
        {maker.name}
      </h1>
      <small className="text-muted">ID {maker.humanId}</small>
      <ScoreSummary maker={maker} />
      <div className="d-flex">
        <div className="mr-5 font-weight-bold">
          <a href={todo("/todo")} className="text-dark-teal">
            Bulk orders
          </a>
        </div>
        <div className="mr-5 font-weight-bold">
          <a href={todo("/todo")} className="text-dark-teal">
            Samples
          </a>
        </div>
        <div className="mr-5 font-weight-bold">
          <a href={maker.humanId + "/products"} className="text-dark-teal">
            Products
          </a>
        </div>
      </div>
    </div>
  </div>
)

const Status = ({ maker }: MakerProps) => (
  <>
    <div className="mb-2">
      <div>Last Updated: {formatDate(maker.updatedAt)}</div>
      <div>
        Vetting Status: {maker.vettingStatus || <EmptyValue variant="dash" />}
      </div>
      <div>Order Status: {maker.orderStatus}</div>
    </div>
    <div
      className="btn-group btn-group-sm"
      role="group"
      aria-label="Maker Status"
    >
      {Object.entries(makerStatusLabels(maker)).map(([status, label]) => (
        <a
          key={status}
          href={`${maker.editUrl}#maker-status-field`}
          className={clsx(
            "btn btn-sm",
            maker.status === status
              ? "btn-teal text-white"
              : "btn-outline-teal",
          )}
        >
          {label}
        </a>
      ))}
    </div>
  </>
)

const MakerCertifications = ({ maker }: MakerProps) => {
  // const [expanded, setExpanded] = useState(false)

  const activeCerts = maker.makerCertifications.nodes.filter((cert) => {
    if (cert.expiredAt) {
      const parts = cert.expiredAt.split("-")
      const expirationDate = new Date(
        parseInt(parts[0]),
        parseInt(parts[1]) - 1,
        parseInt(parts[2]),
      )
      return expirationDate >= new Date()
    } else {
      return true
    }
  })

  // const inactiveCerts = maker.makerCertifications.nodes.filter((cert) => {
  //   if (cert.expiredAt) {
  //     const parts = cert.expiredAt.split("-")
  //     const expirationDate = new Date(parts[0], parts[1] - 1, parts[2])
  //     return expirationDate < new Date()
  //   } else {
  //     return false
  //   }
  // })

  return (
    <BorderBoxContainer>
      <BorderBoxHeading>Active Certifications</BorderBoxHeading>
      {activeCerts.length > 0 ? (
        <BorderBox>
          {activeCerts.map((cert) => (
            <BorderBoxItem key={cert.certificationType}>
              {cert.certificationType}
            </BorderBoxItem>
          ))}
        </BorderBox>
      ) : (
        <BorderBoxEmpty>
          There are no active Maker Certifications.
        </BorderBoxEmpty>
      )}
    </BorderBoxContainer>
  )
}

const LeftCard = ({ maker }: MakerProps) => {
  const makerAttributes = maker.makerAttributes.nodes.filter(
    (makerAttribute) => {
      const makerMakerAttribute = maker.makerMakerAttributes.nodes.find(
        (ma) => ma.makerAttributeId === makerAttribute.id,
      )

      return makerMakerAttribute?.response
    },
  )

  return (
    <>
      <div className="d-flex">
        <h5 className="flex-grow-1">
          Maker since {formatDate(maker.ttmMakerSince, "year")}
        </h5>
        <div className="pl-2 ml-auto">
          <EditLink {...maker} />
        </div>
      </div>

      {maker.url != null ? (
        <div>
          <MaybeLink value={maker.url} />
        </div>
      ) : null}

      <BorderBoxContainer>
        <BorderBoxHeading>Maker Type(s)</BorderBoxHeading>
        {maker.makerTypes.length > 0 ? (
          <BorderBox>
            {maker.makerTypes.map((makerType) => (
              <BorderBoxItem key={makerType}>{makerType}</BorderBoxItem>
            ))}
          </BorderBox>
        ) : (
          <BorderBoxEmpty>No Maker Types have been added yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Attributes</BorderBoxHeading>
        {makerAttributes.length > 0 ? (
          <BorderBox>
            {makerAttributes.map((attribute) => (
              <BorderBoxItem key={attribute.id}>{attribute.text}</BorderBoxItem>
            ))}
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Value Alignments have been added yet.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Maker Environments</BorderBoxHeading>
        {maker.environments.length > 0 ? (
          <BorderBox>
            {maker.environments.map((value) => (
              <BorderBoxItem key={value}>{value}</BorderBoxItem>
            ))}
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Maker Environments have been added yet.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Capabilities</BorderBoxHeading>
        {maker.capabilities.length > 0 ? (
          <BorderBox>
            {maker.capabilities.map((value) => (
              <BorderBoxItem key={value}>{value}</BorderBoxItem>
            ))}
          </BorderBox>
        ) : (
          <BorderBoxEmpty>
            No Maker Capabilities have been added yet.
          </BorderBoxEmpty>
        )}
      </BorderBoxContainer>

      <MakerCertifications maker={maker} />

      <BorderBoxContainer>
        <BorderBoxHeading>Minimum Order Size</BorderBoxHeading>
        <BorderBox>
          <div>
            <div className="mb-2">
              <BorderBoxSubheading>Total Units: </BorderBoxSubheading>
              <MaybeNumber value={maker.moqUnits} />
            </div>
            <div className="mb-2">
              <BorderBoxSubheading>Total Price: </BorderBoxSubheading>
              <FormatCentsMaybe
                cents={maker.moqPriceCents && maker.moqPriceCents}
              />
            </div>
            <div>
              <div>
                <BorderBoxSubheading>Notes</BorderBoxSubheading>
              </div>
              <div className="whitespace-pre-wrap">
                <MaybeText value={maker.moqNotes} />
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Production Capacity</BorderBoxHeading>
        <BorderBox>
          <div>
            <div className="mb-2">
              <div>
                <BorderBoxSubheading>Capacity</BorderBoxSubheading>
              </div>
              <MaybeText value={maker.productionCapacity} />
            </div>
            <div>
              <div>
                <BorderBoxSubheading>Notes</BorderBoxSubheading>
              </div>
              <div className="whitespace-pre-wrap">
                <MaybeText value={maker.productionCapacityNotes} />
              </div>
            </div>
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const BorderBoxSubheading: React.FC = (props) => (
  <h6 className="text-uppercase">{props.children}</h6>
)

const RightCard = (props: MakerProps) => {
  const tabIds = [
    "#main",
    "#storytelling",
    "#documents",
    "#logistics",
    "#policies",
    "#scorecard",
  ]

  const [certsExpanded, setCertsExpanded] = useState(false)
  const [showArchivedContacts, setShowArchivedContacts] = useState(false)

  return (
    <div className="position-relative">
      <Tabs tabIds={tabIds}>
        <div className="d-flex flex-grow-1 justify-content-center mx-4">
          <TabList>
            <StyledTab>Main</StyledTab>
            <StyledTab>Storytelling</StyledTab>
            <StyledTab>Documents</StyledTab>
            <StyledTab>Logistics</StyledTab>
            <StyledTab>Policies</StyledTab>
            <StyledTab>Scorecard</StyledTab>
          </TabList>
        </div>
        <TabPanels>
          <TabPanel>
            <MainPanel {...props} />
          </TabPanel>
          <TabPanel>
            <StoryTellingPanelDisplay {...props} />
          </TabPanel>
          <TabPanel>
            <DocumentsPanel
              slug={props.maker.slug}
              certsExpanded={certsExpanded}
              toggleCertsExpanded={() => setCertsExpanded(!certsExpanded)}
            />
          </TabPanel>
          <TabPanel>
            <LogisticsPanel
              slug={props.maker.slug}
              showArchivedContacts={showArchivedContacts}
              toggleArchivedContacts={() => {
                setShowArchivedContacts(!showArchivedContacts)
              }}
            />
          </TabPanel>
          <TabPanel>
            <PoliciesPanel {...props} />
          </TabPanel>
          <TabPanel>
            <ScorecardPanel {...props} />
          </TabPanel>
        </TabPanels>
      </Tabs>
    </div>
  )
}

const MainPanel = ({ maker }: MakerProps) => {
  return (
    <>
      <TabEditLink {...maker} hash="main" />

      <BorderBoxContainer>
        <BorderBoxHeading>Maker Description</BorderBoxHeading>
        <BorderBox>
          <MaybeText value={maker.profile} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Core Product Categories</BorderBoxHeading>

        <BorderBox>
          {maker.makerProductCategories.nodes.length === 0 ? (
            <EmptyValue variant="message" />
          ) : (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>
                    <h6>Name</h6>
                  </th>
                  <th>
                    <h6>Subcategories</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {maker.makerProductCategories.nodes.map((cat) => (
                  <tr key={cat.id}>
                    <td>{cat.productCategory.name}</td>
                    <td>
                      <div className="d-flex flex-wrap">
                        {cat.productSubcategories.nodes.map((subcat) => (
                          <BorderBoxItem key={subcat.id}>
                            {subcat.name}
                          </BorderBoxItem>
                        ))}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Materials</BorderBoxHeading>

        <BorderBox>
          {maker.makerMaterials.nodes.length === 0 ? (
            <EmptyValue variant="message" />
          ) : (
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>
                    <h6>Name</h6>
                  </th>
                  <th>
                    <h6>Subcategories</h6>
                  </th>
                </tr>
              </thead>
              <tbody>
                {maker.makerMaterials.nodes.map((makerMaterial) => (
                  <tr key={makerMaterial.id}>
                    <td>{makerMaterial.material.name}</td>
                    <td>
                      <div className="d-flex flex-wrap">
                        {makerMaterial.materialSubcategories.nodes.map(
                          (subcat) => (
                            <BorderBoxItem key={subcat.id}>
                              {subcat.name}
                            </BorderBoxItem>
                          ),
                        )}
                      </div>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          )}
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Product Preview</BorderBoxHeading>
        {maker.products.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <>
            <div className="d-grid grid-cols-3 gap-4">
              {maker.products.nodes.map((product) => (
                <a
                  key={product.id}
                  href={product.showUrl}
                  className="d-flex text-dark text-decoration-none"
                >
                  <BorderBox>
                    <div>
                      {product.image && product.image.imgixUrl && (
                        <Imgix
                          src={product.image.imgixUrl}
                          className="mb-2 w-100"
                          htmlAttributes={{
                            alt: "",
                            style: {
                              aspectRatio: `170 / 80`,
                            },
                          }}
                          sizes="200px"
                          imgixParams={{
                            "ar": `170:80`,
                            "fit": `fill`,
                            "fill-color": `white`,
                          }}
                        />
                      )}
                      <h6
                        key={product.id}
                        className="f-header-2 text-uppercase"
                      >
                        {product.name}
                      </h6>
                      <div className="whitespace-pre-wrap line-clamp-5 f-small-text">
                        {product.description}
                      </div>
                    </div>
                  </BorderBox>
                </a>
              ))}
            </div>
            <div className="d-flex flex-grow-1 justify-content-end mt-2">
              <a
                href={maker.humanId + "/products"}
                className="d-flex f-action-text align-items-center"
              >
                View More
                <RightChevronSVG className="ml-1" />
              </a>
            </div>
          </>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Order History</BorderBoxHeading>
        <BorderBox>
          <MakerOrders slug={maker.slug} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Notes</BorderBoxHeading>
        <BorderBox>
          <div className="whitespace-pre-wrap">
            <MaybeText value={maker.internalNotes} />
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <div className="d-grid grid-cols-2 gap-4">
        <BorderBoxContainer>
          <BorderBoxHeading>{`Clients external to ${maker.brandName}`}</BorderBoxHeading>
          <BorderBox>
            {maker.customers.length === 0 ? (
              <EmptyValue variant="message" />
            ) : null}
            {maker.customers.map((text, i) => (
              <BorderBoxItem key={i}>{text}</BorderBoxItem>
            ))}
          </BorderBox>
        </BorderBoxContainer>

        <BorderBoxContainer>
          <BorderBoxHeading>Licenses</BorderBoxHeading>
          <BorderBox>
            {maker.licenses.length === 0 ? (
              <EmptyValue variant="message" />
            ) : null}
            {maker.licenses.map((license, i) => (
              <BorderBoxItem key={i}>{license}</BorderBoxItem>
            ))}
          </BorderBox>
        </BorderBoxContainer>
      </div>
    </>
  )
}

const MakerOrders = (props: { slug: string }) => {
  const result = useInfiniteGraphQLQuery(
    useMakerProfileOrdersQuery,
    ({ pageParam }) => ({
      slug: props.slug,
      first: 10,
      after: pageParam,
    }),
    {
      getNextPageParam: (page) =>
        page.maker.makerOrders.pageInfo.endCursor ?? undefined,
    },
  )

  if (result.status === "loading") return <LoadingIndicator />
  if (result.status === "error") return <RequestError {...result} />

  invariant(result.data, `expected data`)

  const makerOrders = result.data.pages.flatMap(
    (page) => page.maker.makerOrders.nodes,
  )

  return (
    <>
      {makerOrders.length === 0 ? (
        <>
          <EmptyValue variant="message" />
        </>
      ) : (
        <>
          <table className="table nice-table">
            <thead>
              <tr>
                <th>Date Created</th>
                <th>Order</th>
                <th>Client</th>
                <th>Description</th>
                <th>Status</th>
              </tr>
            </thead>
            <tbody className="f-small-text">
              {makerOrders.map((makerOrder) => (
                <tr key={makerOrder.id}>
                  <td>{formatDate(makerOrder.createdAt)}</td>
                  <td className="text-nowrap">
                    <a href={makerOrder.showUrl}>{makerOrder.humanId}</a>
                  </td>
                  <td>{makerOrder.clientOrder.client.name}</td>
                  <td>{makerOrder.clientOrder.description}</td>
                  <td>{makerOrder.humanStatus}</td>
                </tr>
              ))}
            </tbody>
          </table>

          {result.hasNextPage && (
            <div className="pv-3 d-flex flex-grow-1 justify-content-center">
              <button
                onClick={() => result.fetchNextPage()}
                type="button"
                className="btn btn-link f-action-text"
                disabled={result.isFetchingNextPage}
              >
                {result.isLoading ? (
                  "Loading..."
                ) : (
                  <>
                    View More <DownChevronSVG className="ml-1" />
                  </>
                )}
              </button>
            </div>
          )}
        </>
      )}
    </>
  )
}

// const Todo = () => <div className="text-muted">{todo(`Todo`)}</div>

const StoryTellingPanelDisplay = (props: MakerProps) => {
  const { maker } = props

  const makerAttributes = maker.makerAttributes.nodes.filter(
    (makerAttribute) => {
      const makerMakerAttribute = maker.makerMakerAttributes.nodes.find(
        (ma) => ma.makerAttributeId === makerAttribute.id,
      )

      return makerMakerAttribute?.response
    },
  )

  return (
    <>
      <TabEditLink {...maker} hash="storytelling" />

      <div className="d-grid grid-cols-2 gap-4">
        <BorderBoxContainer>
          <BorderBoxHeading>Social Impact</BorderBoxHeading>
          <BorderBox>
            <MaybeText value={maker.socialImpactDescription} />
          </BorderBox>
        </BorderBoxContainer>

        <BorderBoxContainer>
          <BorderBoxHeading>Environmental Impact</BorderBoxHeading>
          <BorderBox>
            <MaybeText value={maker.environmentalImpactDescription} />
          </BorderBox>
        </BorderBoxContainer>
      </div>

      <BorderBoxContainer>
        <BorderBoxHeading>Notable Fact</BorderBoxHeading>
        <BorderBox>
          <MaybeText value={maker.funFact} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Hang Tag Language</BorderBoxHeading>
        <BorderBox>
          <MaybeText value={maker.hangTagLanguage} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Demographics</BorderBoxHeading>
        <BorderBox>
          <table className="table nice-table">
            <tbody>
              <tr>
                <td>Number of employees</td>
                <td>
                  {numberWithCommas(maker.numberOfEmployees) || (
                    <EmptyValue variant="dash" />
                  )}
                </td>
              </tr>
              <tr>
                <td>Number of women employees</td>
                <td>
                  {numberWithCommas(maker.numberOfWomenEmployees) ?? (
                    <EmptyValue variant="dash" />
                  )}
                </td>
              </tr>
            </tbody>
          </table>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Marketing Photos</BorderBoxHeading>
        <BorderBox>
          <div className="d-grid grid-cols-2 gap-4 mb-2 w-100">
            {maker.makerMarketingImages.nodes.map((mmi) => (
              <div className="d-flex align-items-center">
                <div style={{ width: 80, height: 80 }}>
                  <ImageWithControls key={mmi.id} {...mmi} />
                </div>
                <span className="w-75 ml-2" style={{ fontSize: 14 }}>
                  {mmi.description &&
                    mmi.description.substring(0, 140) +
                      (mmi.description.length > 140 ? "..." : "")}
                </span>
              </div>
            ))}
            <a
              href={maker.newMakerMarketingImageUrl}
              className="border border-dashed rounded border-light-teal px-3 pt-3 pb-2 d-flex flex-wrap flex-grow-1 align-items-center justify-content-center"
              style={{ width: 80, height: 80 }}
            >
              <Plus40SVG />
              <VisuallyHidden>Add Marketing Image</VisuallyHidden>
            </a>
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Attributes</BorderBoxHeading>
        {makerAttributes.length > 0 ? (
          <BorderBox>
            <div className="w-100 mx-4 mt-2">
              {makerAttributes.map((makerAttribute, i) => {
                const makerMakerAttribute = maker.makerMakerAttributes.nodes.find(
                  (ma) => ma.makerAttributeId === makerAttribute.id,
                )

                return (
                  <div className="row mb-4 border-bottom" key={i}>
                    <div className="col-8 px-0">
                      <p className="mb-1">{makerAttribute.text}</p>
                      {makerMakerAttribute?.responseText && (
                        <p className="text-muted">
                          {makerMakerAttribute.responseText}
                        </p>
                      )}
                    </div>

                    <div className="col-4">
                      <p className="pl-4 font-weight-bold">YES</p>
                    </div>
                  </div>
                )
              })}
            </div>
          </BorderBox>
        ) : (
          <BorderBoxEmpty>There are no attributes yet.</BorderBoxEmpty>
        )}
      </BorderBoxContainer>
    </>
  )
}

const DocumentsPanel = ({
  slug,
  certsExpanded,
  toggleCertsExpanded,
}: {
  slug: string
  certsExpanded: boolean
  toggleCertsExpanded: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const result = useMakerDocumentsQuery({
    slug,
  })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { maker } = result.data

  const activeCerts = maker.makerCertifications.nodes.filter((cert) => {
    if (cert.expiredAt) {
      const parts = cert.expiredAt.split("-")
      const expirationDate = new Date(
        parseInt(parts[0]),
        parseInt(parts[1]) - 1,
        parseInt(parts[2]),
      )
      return expirationDate >= new Date()
    } else {
      return true
    }
  })

  const inactiveCerts = maker.makerCertifications.nodes.filter((cert) => {
    if (cert.expiredAt) {
      const parts = cert.expiredAt.split("-")
      const expirationDate = new Date(
        parseInt(parts[0]),
        parseInt(parts[1]) - 1,
        parseInt(parts[2]),
      )
      return expirationDate < new Date()
    } else {
      return false
    }
  })

  return (
    <div>
      <TabEditLink {...maker} hash="documents" />
      <BorderBoxContainer>
        <BorderBoxHeading>Certifications</BorderBoxHeading>
        <BorderBox>
          <div className="table-responsive">
            <table className="table nice-table">
              <thead>
                <tr>
                  <th>Certificate</th>
                  <th>Certifier</th>
                  <th>Active</th>
                  <th>Expiration</th>
                  <th>Actions</th>
                </tr>
              </thead>
              <tbody>
                {activeCerts.map((cert) => (
                  <tr key={cert.id}>
                    <td>{cert.certification.name}</td>
                    <td>{cert.certifier}</td>
                    <td>
                      {formatDate(cert.activeFrom, "date") || (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      {formatDate(cert.expiredAt, "date") || (
                        <EmptyValue variant="dash" />
                      )}
                    </td>
                    <td>
                      <DocumentActions document={cert} />
                    </td>
                  </tr>
                ))}
                {certsExpanded &&
                  inactiveCerts.map((cert) => (
                    <tr key={cert.id}>
                      <td>{cert.certification.name}</td>
                      <td>{cert.certifier}</td>
                      <td>
                        {formatDate(cert.activeFrom, "date") || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {formatDate(cert.expiredAt, "date") || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        <DocumentActions document={cert} />
                      </td>
                    </tr>
                  ))}
              </tbody>
            </table>
          </div>
          {!certsExpanded && (
            <button
              className="btn btn-link m-auto d-block"
              onClick={toggleCertsExpanded}
            >
              View Inactive
            </button>
          )}
          {certsExpanded && (
            <button
              className="btn btn-link m-auto d-block"
              onClick={toggleCertsExpanded}
            >
              View Less
            </button>
          )}
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading uppercase={false}>MOUs / NDAs</BorderBoxHeading>
        {maker.makerNdas.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <div className="table-responsive">
              <table className="table nice-table">
                <thead>
                  <tr>
                    {[
                      "Type",
                      "Signer",
                      "Position",
                      "Date of Signature",
                      "Expiration",
                      "Actions",
                    ].map((heading) => (
                      <th key={heading}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {maker.makerNdas.nodes.map((nda) => (
                    <tr key={nda.id}>
                      <td>{nda.documentType.toUpperCase()}</td>
                      <td>{nda.signedByName}</td>
                      <td>{nda.signedByPosition}</td>
                      <td>{formatDate(nda.dateOfSignature)}</td>
                      <td>
                        {formatDate(nda.expirationDate) ?? (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        <DocumentActions document={nda} />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Vetting Survey</BorderBoxHeading>
        {maker.makerVettingSurveys.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <div className="table-responsive">
              <table className="table nice-table">
                <thead>
                  <tr>
                    {[
                      "Completed By",
                      "Position",
                      "Completed On",
                      "Approved By",
                      "Approved At",
                      "Actions",
                    ].map((heading) => (
                      <th key={heading}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {maker.makerVettingSurveys.nodes.map((survey) => (
                    <tr key={survey.id}>
                      <td>
                        {survey.completedBy ? (
                          <Contact contact={survey.completedBy} />
                        ) : (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {survey.completedBy?.jobTitle || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>{formatDate(survey.completedAt, "date")}</td>
                      <td>
                        {survey.approvedBy?.fullName ?? (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {formatDate(survey.approvedAt) ?? (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        <div className="d-flex">
                          <FileDownloadDropdown attachments={survey.surveys} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Audits</BorderBoxHeading>
        {maker.makerAudits.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <div className="table-responsive">
              <table className="table nice-table">
                <thead>
                  <tr>
                    {[
                      "Name",
                      "Completion",
                      "Expiration",
                      "Performed By",
                      "Results",
                      "Actions",
                    ].map((heading) => (
                      <th key={heading}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {maker.makerAudits.nodes.map((audit) => (
                    <tr key={audit.id}>
                      <td>{audit.name}</td>
                      <td>
                        {formatDate(audit.dateOfCompletion, "date") || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {formatDate(audit.expirationDate, "date") || (
                          <EmptyValue variant="dash" />
                        )}
                      </td>
                      <td>
                        {audit.performedBy || <EmptyValue variant="dash" />}
                      </td>
                      <td>{audit.results || <EmptyValue variant="dash" />}</td>
                      <td>
                        <div className="d-flex">
                          <FileDownloadDropdown attachments={audit.documents} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Other Documents</BorderBoxHeading>
        {maker.makerDocuments.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <div className="table-responsive">
              <table className="table nice-table">
                <thead>
                  <tr>
                    {["Date", "Type", "File Name", "Actions"].map((heading) => (
                      <th key={heading}>{heading}</th>
                    ))}
                  </tr>
                </thead>
                <tbody>
                  {maker.makerDocuments.nodes.map((doc) => (
                    <tr key={doc.id}>
                      <td>{formatDate(doc.createdAt)}</td>
                      <td>{doc.documentType}</td>
                      <td>{doc.fileName}</td>
                      <td>
                        <div className="d-flex justify-content-end align-items-center">
                          <DocumentActions document={doc} />
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </BorderBox>
        )}
      </BorderBoxContainer>
    </div>
  )
}

type DocumentActionsDocument = {
  documentUrlInline?: string | null | undefined
  documentUrlAttachment?: string | null | undefined
}

const DocumentActions = ({
  document,
}: {
  document: DocumentActionsDocument
}) => {
  return (
    <div className="d-flex">
      {document.documentUrlInline ? (
        <a
          href={document.documentUrlInline}
          className="d-flex mr-2 text-dark-teal"
        >
          <EyeSVG style={{ width: 17 }} />
          <VisuallyHidden>View document</VisuallyHidden>
        </a>
      ) : null}

      {document.documentUrlAttachment ? (
        <a
          href={document.documentUrlAttachment}
          className="d-flex text-dark-teal"
        >
          <DownloadSVG />
          <VisuallyHidden>Download document</VisuallyHidden>
        </a>
      ) : null}
    </div>
  )
}

const LogisticsPanel = ({
  slug,
  showArchivedContacts,
  toggleArchivedContacts,
}: {
  slug: string
  showArchivedContacts: boolean
  toggleArchivedContacts: (event: React.MouseEvent<HTMLElement>) => void
}) => {
  const result = useMakerLogisticsQuery({
    slug,
  })

  if (result.status === "error") return <RequestError {...result} />
  if (result.status === "loading") return <LoadingIndicator />
  invariant(result.data, `expected data`)
  const { maker } = result.data

  return (
    <>
      <TabEditLink {...maker} hash="logistics" />

      <BorderBoxContainer>
        <BorderBoxHeading>Shipping Ports</BorderBoxHeading>

        {maker.makerShippingPorts.nodes.length === 0 ? (
          <BorderBox>
            <EmptyValue variant="message" />
          </BorderBox>
        ) : (
          <BorderBox>
            <table className="table nice-table">
              <thead>
                <tr>
                  {[
                    "Country",
                    "Port",
                    "Mode of Transit",
                    "", // Default
                  ].map((heading) => (
                    <th key={heading}>{heading}</th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {maker.makerShippingPorts.nodes.map(
                  ({ shippingPort, ...makerShippingPort }) => (
                    <tr
                      key={makerShippingPort.id}
                      className={clsx(
                        makerShippingPort.default && "bg-teal text-white",
                      )}
                    >
                      <td>{shippingPort.country.name}</td>
                      <td>{shippingPort.port}</td>
                      <td>
                        <ModeOfTransitLabel
                          value={shippingPort.modeOfTransit}
                        />
                      </td>
                      <td>
                        {makerShippingPort.default && (
                          <StarSVG
                            className="text-dark-teal"
                            fill="currentColor"
                            style={{ width: 14 }}
                          />
                        )}
                      </td>
                    </tr>
                  ),
                )}
              </tbody>
            </table>
          </BorderBox>
        )}
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-grid grid-flow-col gap-2">
          <div>
            <div className="f-small-text mb-1">Export Experience</div>
            <BorderBoxItem> {maker.exportExperience}</BorderBoxItem>
          </div>

          <div>
            <div className="f-small-text mb-1">Exporting To</div>
            <BorderBoxItem>
              {maker.exportingCountries.nodes.map((c) => c.name).join(", ") || (
                <EmptyValue variant="dash" />
              )}
            </BorderBoxItem>
          </div>
        </div>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Internal Operation Notes</BorderBoxHeading>
        <BorderBox>
          <MaybeText value={maker.internalOperationNotes} />
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Contacts</BorderBoxHeading>
        <div className="d-grid grid-cols-2 gap-4">
          {maker.makerContacts.nodes
            .filter(({ contact, ...makerContact }) => !makerContact.archived)
            .map(({ contact, ...makerContact }) => (
              <BorderBox key={makerContact.id}>
                <div className="position-relative flex-grow-1">
                  <ContactDetail
                    contact={contact}
                    isDefault={makerContact.default}
                  />
                </div>
              </BorderBox>
            ))}

          {showArchivedContacts &&
            maker.makerContacts.nodes
              .filter(({ contact, ...makerContact }) => makerContact.archived)
              .map(({ contact, ...makerContact }) => (
                <BorderBox key={makerContact.id}>
                  <div className="position-relative flex-grow-1">
                    <ContactDetail
                      contact={contact}
                      isDefault={makerContact.default}
                    />
                  </div>
                </BorderBox>
              ))}
        </div>
        <div className="mt-2">
          <button
            className="btn btn-teal text-light w-25 m-auto"
            style={{ marginTop: "20px !important" }}
            onClick={toggleArchivedContacts}
          >
            {showArchivedContacts ? "Hide" : "Show"} Archived
          </button>
        </div>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <BorderBoxHeading>Address</BorderBoxHeading>

        <BorderBox>
          {maker.address || <EmptyValue variant="message" />}
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>Incoterms</BorderBoxHeading>
        </div>

        <BorderBox>
          <div className="d-grid grid-cols-6 gap-4 mb-2">
            {maker.incoterms.nodes.length === 0 && (
              <EmptyValue variant="message" />
            )}
            {maker.incoterms.nodes.map((incoterm) => (
              <BorderBoxItem key={incoterm.id}>
                <div className="text-center">{incoterm.name}</div>
              </BorderBoxItem>
            ))}
          </div>
        </BorderBox>
      </BorderBoxContainer>

      <BorderBoxContainer>
        <div className="d-flex w-100">
          <BorderBoxHeading>PREFERRED PARCEL CARRIERS</BorderBoxHeading>
        </div>
        <BorderBox>
          <div className="d-grid grid-cols-6 gap-4 mb-2">
            {maker.parcelCarriers.nodes.length === 0 && (
              <EmptyValue variant="message" />
            )}
            {maker.parcelCarriers.nodes.map((carrier) => (
              <BorderBoxItem key={carrier.id}>
                <div className="text-center">{carrier.name}</div>
              </BorderBoxItem>
            ))}
          </div>
        </BorderBox>
      </BorderBoxContainer>
    </>
  )
}

const PoliciesPanel = ({ maker }: MakerProps) => (
  <div className="pt-6">
    <TabEditLink {...maker} hash="policies" />

    {maker.makerQuestions.nodes.map((makerQuestion, i) => {
      const makerMakerQuestion = maker.makerMakerQuestions.nodes.find(
        (mq) => mq.makerQuestionId === makerQuestion.id,
      )

      const response =
        typeof makerMakerQuestion?.response === "boolean"
          ? makerMakerQuestion.response
            ? "YES"
            : "NO"
          : "UNANSWERED"

      return (
        <div className="row mb-4" key={i}>
          <div className="col-8">
            <p className="mb-1">{makerQuestion.question}</p>
            {makerMakerQuestion?.responseText && (
              <small className="text-muted">
                {makerMakerQuestion.responseText}
              </small>
            )}
          </div>

          <div className="col-4">
            <p className="pl-4 font-weight-bold">{response}</p>
          </div>
        </div>
      )
    })}
  </div>
)

type ScoreProps = {
  label: string
  value?: number | null
}

const Score = ({ label, value }: ScoreProps) => {
  return (
    <div style={{ position: "relative", height: "90px" }} className="m-3">
      <div style={{ width: "150px", height: "75px", overflow: "hidden" }}>
        <div style={{ width: "150px", height: "150px" }}>
          <CircularProgressbar
            value={((value || 0) / 2) * 10}
            styles={buildStyles({
              rotation: 0.75,
              strokeLinecap: "butt",
              pathColor: "#ff6f61",
            })}
          />
        </div>
      </div>

      <div
        style={{ position: "absolute", left: 0, right: 0, top: "36px" }}
        className="text-center"
      >
        <h3 className="f-title font-weight-bold mb-0">
          {value == null ? "-" : value.toFixed(1)}
        </h3>
        <small>{label}</small>
      </div>
    </div>
  )
}

type MakerScoreRowProps = {
  label: string
  value?: number | null
  children?: ReactNode
  expandable?: boolean
}

const MakerScoreRow = ({
  label,
  value,
  children,
  expandable,
}: MakerScoreRowProps) => {
  const [expanded, setExpanded] = useState<boolean>(false)

  return (
    <div className={clsx("w-100", { "mb-2": children })}>
      <div
        className={clsx("d-flex", "px-2", "py-3", {
          "font-weight-bold": children,
        })}
        style={{
          borderBottom: "1px solid #DCDCDC",
        }}
      >
        <h6
          className={clsx(
            "text-uppercase",
            "mb-0",
            "pr-4",
            expandable && children ? "font-weight-bold" : "font-weight-light",
          )}
          style={{
            letterSpacing: "0.05em",
          }}
        >
          {label}
        </h6>
        {expandable ? (
          <div
            className="ml-auto d-flex justify-content-between"
            style={{ width: "50px" }}
          >
            {value == null ? "-" : value}
            {expandable && (
              <span>
                {children && (
                  <span
                    style={{ cursor: "pointer" }}
                    onClick={() => setExpanded(!expanded)}
                  >
                    {expanded ? <DownChevronIcon /> : <UpChevronIcon />}
                  </span>
                )}
              </span>
            )}
          </div>
        ) : (
          <div className="progress w-25 ml-auto">
            <div
              className="progress-bar"
              role="progressbar"
              style={{ width: `${(value || 0) * 10}%` }}
              aria-valuenow={value || 0}
              aria-valuemin={0}
              aria-valuemax={10}
            />
          </div>
        )}
      </div>

      {expandable &&
        expanded &&
        children &&
        React.Children.map(children, (child) =>
          React.isValidElement(child)
            ? React.cloneElement(child, { expandable })
            : child,
        )}
    </div>
  )
}

const ScorecardPanel = ({ maker }: MakerProps) => {
  const makerScore = maker.makerScore
  return (
    <>
      {makerScore ? (
        <>
          <small>
            <b>{"Last Updated: "}</b>
            {formatDateTimeLong(makerScore.updatedAt)}
          </small>

          <BorderBoxContainer>
            <BorderBoxHeading>Overall Score</BorderBoxHeading>

            <BorderBox>
              <div className="d-flex align-items-baseline">
                <Score value={makerScore.overallScore} label="Overall Score" />
                <div
                  className="ml-2"
                  style={{ fontSize: "18px", lineHeight: "26px" }}
                >
                  {makerScore.overallScorePercentile !== null && (
                    <p>
                      Compared to similar makers, it is in the{" "}
                      {makerScore.overallScorePercentile?.toFixed(0)}%
                      percentile.
                    </p>
                  )}
                </div>
              </div>
            </BorderBox>
          </BorderBoxContainer>

          <BorderBoxContainer>
            <BorderBoxHeading>Core Score</BorderBoxHeading>
            <BorderBox>
              <div className="d-flex align-items-baseline mb-4">
                <Score value={makerScore.coreScore} label="Core Score" />
                <div
                  className="ml-2"
                  style={{ fontSize: "18px", lineHeight: "26px" }}
                >
                  {makerScore.coreScorePercentile !== null && (
                    <p>
                      Compared to similar makers, it is in the{" "}
                      {makerScore.coreScorePercentile?.toFixed(0)}% percentile.
                    </p>
                  )}
                </div>
              </div>
              <MakerScoreRow
                label="Overall Value Alignment"
                value={makerScore.overallValueAlignment}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Sustainable materials"
                  value={makerScore.sustainableMaterials}
                />
                <MakerScoreRow
                  label="Environmental facilities"
                  value={makerScore.environmentalFacilities}
                />
                <MakerScoreRow
                  label="Environmentally friendly"
                  value={makerScore.environmentallyFriendly}
                />
                <MakerScoreRow label="Fairtrade" value={makerScore.fairtrade} />
                <MakerScoreRow
                  label="Fair labor practices or social membership"
                  value={makerScore.fairLaborPractices}
                />
                <MakerScoreRow
                  label="Special social programs"
                  value={makerScore.specialSocialPrograms}
                />
                <MakerScoreRow
                  label="Women owned and/or operated"
                  value={makerScore.womenOwned}
                />
                <MakerScoreRow
                  label="Artisanal or inclusive ownership and/or employment"
                  value={makerScore.inclusiveOwnership}
                />
                <MakerScoreRow
                  label="Union group"
                  value={makerScore.unionGroup}
                />
                <MakerScoreRow label="B Corp" value={makerScore.bCorp} />
              </MakerScoreRow>

              <MakerScoreRow
                label="Certifications And Audits"
                value={makerScore.certificationsAndAudits}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Relevant and valid social and environmental certificates"
                  value={makerScore.certifications}
                />
                <MakerScoreRow
                  label="Relevant audits"
                  value={makerScore.audits}
                />
              </MakerScoreRow>

              <MakerScoreRow
                label="Amenities and Social Services"
                value={makerScore.amenitiesAndSocialServices}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Access to clean water, food, sanitation and hygiene services in the workplace"
                  value={makerScore.generalServices}
                />
                <MakerScoreRow
                  label="Access to healthcare in the workplace"
                  value={makerScore.healthcare}
                />
                <MakerScoreRow
                  label="Access to PPE in the workplace"
                  value={makerScore.ppe}
                />
                <MakerScoreRow
                  label="Training for employees"
                  value={makerScore.training}
                />
                <MakerScoreRow
                  label="Social services provided to employees in the workplace"
                  value={makerScore.socialServices}
                />
                <MakerScoreRow
                  label="Services provided to the local community"
                  value={makerScore.localCommunityServices}
                />
              </MakerScoreRow>

              <MakerScoreRow
                label="Environmental Policies and Initiatives"
                value={makerScore.environmentalPoliciesAndInitiatives}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Company environmental policy provided"
                  value={makerScore.environmentalPolicy}
                />
                <MakerScoreRow
                  label="Alignment external sustainability framework or certification system"
                  value={makerScore.sustainabilityFramework}
                />
                <MakerScoreRow
                  label="Designated staff"
                  value={makerScore.designatedStaff}
                />
                <MakerScoreRow
                  label="Environmental reduction targets"
                  value={makerScore.reductionTargets}
                />
                <MakerScoreRow
                  label="Recycle, reuse or up-cycle system"
                  value={makerScore.recycling}
                />
                <MakerScoreRow
                  label="Tracking and/or reporting on energy use, water use, waste and emission and/or transportation impact"
                  value={makerScore.trackingAndReporting}
                />
                <MakerScoreRow
                  label="Environmental risk assessment"
                  value={makerScore.riskAssessment}
                />
                <MakerScoreRow
                  label="Hazardous chemicals records"
                  value={makerScore.hazardousChemicalRecords}
                />
              </MakerScoreRow>

              <MakerScoreRow
                label="Employee Policies"
                value={makerScore.employeePolicies}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Employee handbook and/or policy attached"
                  value={makerScore.handbook}
                />
                <MakerScoreRow
                  label="Employee compensation policy"
                  value={makerScore.compensationPolicy}
                />
                <MakerScoreRow
                  label="Sick leave policy, overtime and vacation policy"
                  value={makerScore.leavePolicy}
                />
                <MakerScoreRow
                  label="Employee harassment and grievance policy"
                  value={makerScore.harassmentPolicy}
                />
                <MakerScoreRow
                  label="Maternity leave policy"
                  value={makerScore.maternityLeave}
                />
              </MakerScoreRow>

              <MakerScoreRow
                label="Operational Policies"
                value={makerScore.operationalPolicies}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Fire and safety policy provided"
                  value={makerScore.fireAndSafetyPolicy}
                />
                <MakerScoreRow
                  label="Quality control policy provided"
                  value={makerScore.qualityControlPolicy}
                />
              </MakerScoreRow>

              <MakerScoreRow
                label="Onboarding Participation"
                value={makerScore.onboardingParticipation}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Vetting survey currency"
                  value={makerScore.vettingSurveyCurrency}
                />
                <MakerScoreRow
                  label="TTM agreements"
                  value={makerScore.ttmAgreements}
                />
                <MakerScoreRow
                  label="Platform use"
                  value={makerScore.trainingParticipation}
                />
              </MakerScoreRow>
            </BorderBox>
          </BorderBoxContainer>

          <BorderBoxContainer>
            <BorderBoxHeading>Performance Score</BorderBoxHeading>
            <BorderBox>
              <div className="d-flex align-items-baseline mb-4">
                <Score
                  value={makerScore.performanceScore}
                  label="Performance Score"
                />
                <div
                  className="ml-2"
                  style={{ fontSize: "18px", lineHeight: "26px" }}
                >
                  {makerScore.performanceScorePercentile !== null && (
                    <p>
                      Compared to similar makers, it is in the{" "}
                      {makerScore.performanceScorePercentile?.toFixed(0)}%
                      percentile.
                    </p>
                  )}
                </div>
              </div>

              <MakerScoreRow
                label="Sampling Experience"
                value={makerScore.samplingExperience}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Timing"
                  value={makerScore.samplingTiming}
                />
                <MakerScoreRow
                  label="Quality"
                  value={makerScore.samplingQuality}
                />
                <MakerScoreRow label="Cost" value={makerScore.samplingCost} />
              </MakerScoreRow>

              <MakerScoreRow
                label="Production Experience"
                value={makerScore.productionExperience}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Timing"
                  value={makerScore.productionTiming}
                />
                <MakerScoreRow
                  label="Quality"
                  value={makerScore.productionQuality}
                />
                <MakerScoreRow label="Cost" value={makerScore.productionCost} />
              </MakerScoreRow>

              <MakerScoreRow
                label="Maker Communication"
                value={makerScore.makerCommunication}
                expandable={makerScore.showBreakdown}
              >
                <MakerScoreRow
                  label="Correspondence"
                  value={makerScore.correspondence}
                />
                <MakerScoreRow
                  label="Production communications"
                  value={makerScore.communications}
                />
                <MakerScoreRow
                  label="Platform use"
                  value={makerScore.platformUse}
                />
              </MakerScoreRow>
            </BorderBox>
          </BorderBoxContainer>
        </>
      ) : (
        <>No score has been computed for this maker yet.</>
      )}
    </>
  )
}

export default withBasics(MakerProfile)
